import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

// Types
import {
	TContentfulSection, THeadMeta, TNewsletterPost,
} from '../utils/types';

// Contentful
import SectionBuilder from '../contentful/SectionBuilder';

// Molecules
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';
import NewsletterList from 'src/components/Sections/Newsletter/NewsletterList';

// Sections

type TNewsletterPage = {
	data: {
		contentfulPage: {
			sections: TContentfulSection[]
		} & THeadMeta
		allNewsletterPosts: {
			nodes: TNewsletterPost[]
		}
		allFile: {
			nodes: {
				id: string
				childImageSharp: any
			}[]
		}
	}
	// eslint-disable-next-line react/no-unused-prop-types
	pageContext: {
		postsPerPage: number
	}
}

const Newsletter: React.FC<TNewsletterPage> = (props) => {
	const {
		data: { contentfulPage, allNewsletterPosts, allFile },
		pageContext: { postsPerPage },
	} = props;

	const data = useMemo(
		() => {
			return {
				allNewsletterPosts: {
					nodes: allNewsletterPosts.nodes.map((np) => {
						return {
							...np,
							thumbnail: allFile.nodes.find(f => f.name === `newsletter-thumbnail-${np.id}`)?.childImageSharp,
						};
					}),
				},
			};
		},
		[allFile, allNewsletterPosts]
	);

	return (
		<main>
			{SectionBuilder(contentfulPage.sections)}
			<NewsletterList newsletterPosts={[...data.allNewsletterPosts.nodes]} postsPerPage={postsPerPage} />
		</main>
	);
};

export default Newsletter;

export const Head: React.FC<TNewsletterPage> = (props) => {
	const { data: { contentfulPage } } = props;
	return <HeadMeta meta={contentfulPage.meta || {}} />;
};

export const pageQuery = graphql`
    query NewsletterPageQuery($slug: String!, $skip : Int, $limit: Int) {
		contentfulPage(slug: {eq: $slug}) {
            ...PageFields
        }
		allNewsletterPosts(
			skip: $skip,
			limit: $limit,
			sort: {order: DESC, fields: publish_date},
		) {
			nodes {
               ...NewsletterPostFields
            }
        }
		allFile(filter: {name: {regex: "/newsletter-thumbnail/" }}) {
			nodes {
				id
				name
				childImageSharp {
					gatsbyImageData
				}
			}
		}
    }
`;
