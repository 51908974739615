import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

// Types
import { TNewsletterPost } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

// Molecules
import Section from 'fatcat-ui-library/components/Molecules/Section';
import NewsletterCard from 'src/components/Molecules/Cards/NewsletterCard';

// Organisms
import Pagination from '../../Organisms/Pagination/Pagination';

import { chunk } from '../../../utils/helpers';

const NewsletterList: React.FC<{ newsletterPosts: TNewsletterPost[], postsPerPage: number}> = (props) => {
	const {
		newsletterPosts: allNewsletterPosts,
		postsPerPage,
	} = props;
	const { search, pathname } = useLocation();
	const urlSearchParams = new URLSearchParams(search);

	// remove url param **page** if typed directly in search bar and value is one
	if (urlSearchParams.get('page') === '1') {
		urlSearchParams.delete('page');
		const newSearch = urlSearchParams.toString();
		navigate(newSearch ? `?${newSearch}` : pathname);
	}

	// get filterd query params
	const searchParams = urlSearchParams.get('search');
	const currentPage = parseInt(urlSearchParams.get('page') || '1', 10);

	// filter blogs from query params
	const posts = search ? allNewsletterPosts.filter((blog) => {
		const hasTitle = searchParams ? blog.title.toLowerCase().includes(searchParams) : true;
		return hasTitle;
	}) : allNewsletterPosts;

	// calculate number of pages
	const numberOfBlogPages = Math.ceil(posts.length / postsPerPage);

	return (
		<Section
			paddingBottom="s80"
			scale
		>
			{/* <SearchInput items={allBlogs.map(blog => blog.title)} tags={tags.map(tag => tag.identifier)} /> */}
			<Flex
				wrap
				gap="20px"
				padding={['b20', 't80']}
				largeTablet={['justifyContentCenter']}
				tablet={[{ gap: '24px', padding: ['t48'] }]}
			>
				{chunk(posts, postsPerPage).map((grp, index) => index + 1 === currentPage && grp.map((post, index) => <NewsletterCard key={post.title} {...post} />))}
			</Flex>
			<Pagination currentPage={currentPage} numPages={numberOfBlogPages} />
		</Section>
	);
};

export default NewsletterList;
